export const INPUT_REGEX_ALPHABETICAL_WITH_SPACE = /^[a-zA-Z ]*$/;
export const INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE = /^[a-zA-Z \-']*$/;
export const INPUT_REGEX_ALPHANUMERIC_WITH_SPACE = /^[a-zA-Z0-9 ]*$/;
export const INPUT_REGEX_ALPHANUMERIC_WITH_SINGLE_SPACE = /^(?!.* {2})[a-zA-Z0-9 ]*$/;
export const INPUT_REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/;
export const INPUT_REGEX_ALPHANUMERIC_WITH_SPACE_HYPHEN_APOSTROPHE = /^[a-zA-Z0-9 \-']*$/;
export const INPUT_REGEX_MONEY_VALUE = /^[0-9]*$/;
export const INPUT_REGEX_NUMBER_WITH_SPACE = /^[0-9 ]*$/;
export const INPUT_REGEX_NAME = /^[a-zA-Z\-' ]*$/;
export const INPUT_REGEX_NAME_SPECIAL_CHARS = /^([a-zA-Z!£$%^&*()<>_" \-']*)$/; // Users from aggregators may have special characters in names
export const INPUT_REGEX_FLAT = /^[a-zA-Z0-9 /]*$/;
// Allows numbers in the pet name but not for the first character
export const INPUT_REGEX_PET_NAME = /^([a-zA-Z \-'][a-zA-Z0-9 \-']*)?$/;
export const INPUT_REGEX_PET_NAME_SPECIAL_CHARS = /^([a-zA-Z&!_ \-'][a-zA-Z0-9&!_ \-']*)?$/; // Users from aggregators may have special characters in names
