import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { ErrorPanel } from 'components/StatusPanel';

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  h2 {
    ${fonts.headingSmall};
  }
  h3 {
    ${fonts.headingXSmall};
  }
  p {
    ${fonts.paragraphLarge}
  }

  margin-bottom: ${spacing(6)};
`;

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin: ${spacing(-2)} 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;
